// 仅支持 chrome 滚动条样式

/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
    width: 5px;
    background-color: #fff;
    height: 8px;
}
// 滚动条上下方按钮
::-webkit-scrollbar-button {
    display: none;
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.ant-back-top {
    display: none;
}
/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #ccc;
}

.base-style {
    background-color: #fff;
    margin-bottom: 1rem;
    padding: 1.5rem;
    border-radius: 5px;
    box-shadow: 0 2px 13px 0 rgba(228, 228, 228, 0.6);
}

.ant-layout {
    transition: all 0.2s;
}
.ant-table {
    min-height: 500px;
}
.ant-table-body,
.ant-table-content {
    overflow: auto !important;
}

.blacklist-group .container .table-data,
.blacklist .container .table-data,
.staff-group .container .table-data,
.staff .container .table-data,
.visitor-group .container .table-data,
.visitor .container .table-data {
    overflow-x: hidden !important;
    overflow-y: hidden !important;
}
